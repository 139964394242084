var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("HomePage", {
    staticClass: "main-content",
    attrs: {
      "background-img": _vm.backgroundImg,
      "logo-img": _vm.logoImg,
      "swipe-img-list": _vm.swipeImgList,
      "app-code": _vm.APP_CODE,
      "api-hosts": _vm.API_HOSTS,
      "button-style-ios": _vm.buttonStyleIOS,
      "button-style-android": _vm.buttonStyleAndroid,
      "button-style-pc": _vm.buttonStylePC,
      "download-recommend-tag-img": _vm.downloadRecommendTagImg,
      "logo-icon": _vm.logoIcon,
      "button-after-style": _vm.buttonAfterStyle
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }