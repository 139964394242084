<template>
  <HomePage
    class="main-content"
    :background-img="backgroundImg"
    :logo-img="logoImg"
    :swipe-img-list="swipeImgList"
    :app-code="APP_CODE"
    :api-hosts="API_HOSTS"
    :button-style-ios="buttonStyleIOS"
    :button-style-android="buttonStyleAndroid"
    :button-style-pc="buttonStylePC"
    :download-recommend-tag-img="downloadRecommendTagImg"
    :logo-icon="logoIcon"
    :button-after-style="buttonAfterStyle"
  />
</template>

<script>
import HomePage from '@/components/common/home-page/index.vue'
import backgroundImg from './assets/images/bg.png'
import logoImg from './assets/images/logo.png'
import downloadRecommendTagImg from './assets/images/hot_tag_recommend_new.png'
import logoIcon from './assets/images/logo_icon.png'
import { APP_CODE, API_HOSTS } from '../config'
import buttonImg from './assets/images/icon_download.png'

export default {
  components: {
    HomePage,
  },
  data() {
    return {
      backgroundImg,
      logoImg,
      logoIcon,
      downloadRecommendTagImg,
      swipeImgList: [
        {
          bg: require('./assets/images/swipe_bg_1.png'),
          title: require('./assets/images/swipe_title_1.png'),
        },
        {
          bg: require('./assets/images/swipe_bg_2.png'),
          title: require('./assets/images/swipe_title_2.png'),
        },
        {
          bg: require('./assets/images/swipe_bg_3.png'),
          title: require('./assets/images/swipe_title_3.png'),
        },
        {
          bg: require('./assets/images/swipe_bg_4.png'),
          title: require('./assets/images/swipe_title_4.png'),
        },
      ],
      APP_CODE,
      API_HOSTS,
      buttonStyleIOS: {
        borderRadius: '10px',
        border: '1px solid #F8A9FF',
        background: 'rgba(241, 49, 182, 0.80)',
        boxShadow: '0px 0px 20px 0px rgba(255, 0, 153, 0.50)',
      },
      buttonAfterStyle: {
        backgroundSize: 'cover',
        width: '14px',
        height: '16px',
        marginLeft: '4px',
        background: `url(${buttonImg}) no-repeat center`,
      },
      buttonStyleAndroid: {
        borderRadius: '10px',
        border: '1px solid #F8A9FF',
        background: 'rgba(241, 49, 182, 0.80)',
        boxShadow: '0px 0px 20px 0px rgba(255, 0, 153, 0.50)',
      },
      buttonStylePC: {
        borderRadius: '10px',
        border: '1px solid #F8A9FF',
        background: 'rgba(241, 49, 182, 0.80)',
        boxShadow: '0px 0px 20px 0px rgba(255, 0, 153, 0.50)',
      },
    }
  },
}
</script>

<style>
@import url('../../../assets/css/minireset.min.css');
@import url('../../../assets/css/common.css');
@import url('../common.css');
</style>

<style scoped>
.main-content ::v-deep .home__logo__img {
  height: 40px;
  margin-left: 20px;
  margin-right: auto;
}
</style>